<template>
  <v-parallax
    height="1000"
    dark
    src="@/assets/domkyrkan_medium.jpg"
  >
    <v-layout
      align-center
      column
      justify-center
    >
      <div class="blur">
      <h1> <textloader-component textKey="UppsalaRubrik"/> </h1>
      <paragraph-component> <textloader-component textKey="UppsalaBrödtext"/> </paragraph-component>
      </div>
    </v-layout>
  </v-parallax>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  }
}
</script>

<style>
.blur{
/* padding: 200px 0; */
backdrop-filter: blur(4px);
/* mask: radial-gradient(transparent, black 60%) */
}
</style>
